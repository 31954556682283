import * as React from 'react';
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { motion } from 'framer-motion';
import {enteringFormTransition, inputTransition} from "../../constants/transitions";
import { Controller, useForm } from 'react-hook-form';
import Send from "@mui/icons-material/Send";
import withTheme from "../../components/WithTheme";
import Card from "@mui/material/Card";
import {CardActionArea, Stack} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import {WhatsApp} from "@mui/icons-material";
import {navigate} from "gatsby";
const Whatsapp = () => {
    const { handleSubmit, control, formState: {errors}, setError } = useForm();
    const [ loading, setLoading ] = React.useState<boolean>(false);

    const onSubmit = (data: any) => {
        setLoading( true );

        data.message = data.message.replace(' ','+');

        window.open(
            `https://wa.me/52${ data.phone }?text=${ encodeURIComponent( data.message ) }`,
            '_blank'
        )

        setLoading( false );
    };

    return(
        <Layout>
            <Seo
                title="WhatsApp sin agregar contacto"
                description="¡Así de simple!"
            />
            <Container sx={{ mt: 12 }} maxWidth="sm">
                <motion.div
                    initial={{
                        y: 20,
                        opacity: 0
                    }}
                    animate={{
                        y: 0,
                        opacity: 1,
                        transition: {
                            ...inputTransition,
                            duration: 1
                        }
                    }}
                >
                    <Stack spacing={ 2 } alignItems="center" direction="row">
                        <WhatsApp sx={{
                            color: '#25D366'
                        }}/>
                        <Typography
                            color="text.primary"
                            variant="h6">
                            ¡Envía un WhatsApp sin agregar al contacto!
                        </Typography>
                    </Stack>

                </motion.div>
                <form>
                    <motion.div initial='initial' animate='animate' exit='exit'>
                        <motion.span variants={enteringFormTransition}>
                            <motion.div variants={inputTransition}>
                                <Grid mt={2} mb={2} container>
                                    <Grid xs={ 12 } item>
                                        <Controller
                                            name="phone"
                                            control={control}
                                            rules={{
                                                required: 'El número es requerido.',

                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <TextField
                                                    InputProps={{
                                                        disableUnderline: true
                                                    }}
                                                    fullWidth
                                                    variant="filled"
                                                    onChange={onChange}
                                                    value={value}
                                                    label="Teléfono al que deseas enviar el mensaje"
                                                />
                                            )}
                                        />
                                        {errors.phone && <Typography variant="caption" sx={{color:'red'}}>{errors.phone.message}</Typography>}
                                    </Grid>
                                </Grid>
                            </motion.div>
                            <motion.div variants={inputTransition}>
                                <Grid mb={2}>
                                    <Grid item>
                                        <Controller
                                            name="message"
                                            rules={{
                                                required: 'El mensaje es requerido.'
                                            }}
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <TextField
                                                    InputProps={{
                                                        disableUnderline: true,
                                                    }}
                                                    multiline
                                                    fullWidth
                                                    variant="filled"
                                                    onChange={onChange}
                                                    value={value}
                                                    label="Mensaje" />
                                            )}
                                        />
                                        {errors.message && <Typography variant="caption" sx={{color:'red'}}>{errors.message.message}</Typography>}
                                    </Grid>
                                </Grid>
                            </motion.div>
                            <motion.div variants={inputTransition}>
                                <Button
                                    disabled={ loading }
                                    sx={{
                                        width: '100%',
                                        color: 'white',
                                        padding: '14px 0',
                                        borderRadius: 3.5,
                                        textTransform: 'none',
                                    }}
                                    variant="contained"
                                    startIcon={ loading ? <CircularProgress sx={{ color:'inherit' }} size={ 12 }/> : <Send/> }
                                    onClick={handleSubmit( onSubmit )}
                                >
                                    Enviar WhatsApp
                                </Button>
                            </motion.div>
                        </motion.span>
                    </motion.div>
                </form>
                <motion.div
                    initial={{
                        y: 20,
                        opacity: 0
                    }}
                    animate={{
                        y: 0,
                        opacity: 1,
                        transition: {
                            ...inputTransition,
                            delay: 4,
                        }
                    }}
                >
                    <Card sx={{ my: 8 }} elevation={ 4 }>
                        <CardActionArea onClick={ () => navigate('/')}>
                            <CardContent>
                                <Stack>
                                    <Typography color="text.primary" variant="body2">
                                        ¿Eres emprendedor/a?
                                    </Typography>
                                    <Typography color="text.secondary" variant="caption">
                                        Crea tu perfil en OhMyZone!,
                                        recibe mensajes y publica tus productos o servicios.
                                    </Typography>
                                    <Button sx={{
                                        mt: 1,
                                        borderRadius: 3.5,
                                        textTransform: 'none',
                                    }} size="small" color="secondary" variant="outlined">
                                        ¡Regístrate gratis!
                                    </Button>
                                </Stack>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </motion.div>
            </Container>
        </Layout>
    )
}

export default withTheme( Whatsapp );